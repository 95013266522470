'use client'

import { useEffect, useMemo, useState } from 'react'
import uuid from 'uuid'
import { Button, Cell, Divider, Dialog, Navigation, Spacer, Text } from '@vinted/web-ui'
import { X16 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import { navigateToPage } from 'libs/utils/window'
import { SPECIAL_VERIFICATION_FORM_URL_WITH_REF } from 'constants/routes'
import { FaqEntryType } from 'constants/faq-entry'
import { AccessChannel } from 'constants/index'
import { UiState } from 'constants/ui'
import { dismissTaxpayerRestrictionModal } from 'data/api'
import useTracking from 'hooks/useTracking'
import { clickEvent, viewEvent } from 'libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'
import { ViewableElement } from 'constants/tracking/viewable-elements'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import useRefUrl from 'hooks/useRefUrl'

import FaqEntryUrl from 'components/FaqEntryUrl'

type Props = {
  show: boolean
  screen?: Screen
  onClose: () => void
}

const TaxpayersSpecialVerificationRestrictionInfoModal = ({ show, screen, onClose }: Props) => {
  const translate = useTranslate(
    'taxpayer_special_verification.verification_restriction.verification_restriction_modal',
  )
  const { track } = useTracking()
  const refUrl = useRefUrl()

  const specialVerificationSessionId = useMemo(() => uuid.v4(), [])

  const [uiState, setUiState] = useState(UiState.Idle)

  useEffect(() => {
    if (!show) return

    track(
      viewEvent({
        screen,
        target: ViewableElement.TaxpayersSpecialVerificationBalanceBlockModal,
      }),
    )
  }, [screen, show, track])

  const handleFinaliseTaxpayersSpecialVerificationForm = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    if (uiState === UiState.Pending) {
      event.preventDefault()

      return
    }

    track(
      clickEvent({
        screen,
        target: ClickableElement.TaxpayersSpecialVerificationBalanceBlockModal,
        targetDetails: 'finalise',
      }),
    )

    dismissTaxpayerRestrictionModal()
    setUiState(UiState.Pending)
    navigateToPage(
      `${SPECIAL_VERIFICATION_FORM_URL_WITH_REF(refUrl, specialVerificationSessionId)}`,
    )
  }

  const handleLearnMore = () => {
    track(
      clickEvent({
        screen,
        target: ClickableElement.TaxpayersSpecialVerificationBalanceBlockModal,
        targetDetails: 'learn',
      }),
    )

    dismissTaxpayerRestrictionModal()
  }

  const handleClose = () => {
    track(
      clickEvent({
        screen,
        target: ClickableElement.TaxpayersSpecialVerificationBalanceBlockModal,
        targetDetails: 'close',
      }),
    )
    onClose()
  }

  return (
    <Dialog show={show} className="u-flexbox u-flex-direction-column">
      <Navigation
        body={<Text type={Text.Type.Title} text={translate('header')} bold />}
        right={
          <Button
            styling={Button.Styling.Flat}
            onClick={handleClose}
            testId="taxpayers-special-verification-restriction-modal-close"
            iconName={X16}
            inline
          />
        }
      />
      <Divider />
      <Cell>
        <Text
          type={Text.Type.Heading}
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
          text={translate('title')}
        />
        <Spacer size={Spacer.Size.Large} />
        <Text type={Text.Type.Body} text={translate('body')} />
        <Spacer size={Spacer.Size.XLarge} />
        <Button
          styling={Button.Styling.Filled}
          isLoading={uiState === UiState.Pending}
          testId="special-verification-restriction-primary-button"
          text={translate('actions.verify_your_information')}
          onClick={handleFinaliseTaxpayersSpecialVerificationForm}
        />
        <Spacer size={Spacer.Size.Large} />
        <FaqEntryUrl
          type={FaqEntryType.Dac7SpecialVerification}
          accessChannel={AccessChannel.ProductLink}
          render={url => (
            <a
              data-testid="dac7-special-verification-faq-entry-link"
              className="u-disable-underline"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                text={translate('actions.learn_more')}
                testId="special-verification-restriction-secondary-button"
                onClick={handleLearnMore}
              />
            </a>
          )}
        />
      </Cell>
    </Dialog>
  )
}

export default TaxpayersSpecialVerificationRestrictionInfoModal
